import React from "react"

const DateTimeDisplay = ({ value, type }) => {
  return (
    <div className="countdown">
      <h2 className="time">{value < 10 ? `0${value}` : value}</h2>
      <p className="time-type"> {type}</p>
    </div>
  )
}

export default DateTimeDisplay

