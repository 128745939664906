import React, { useState } from "react"
import { useCountdown } from "./CustomHook/useCountdown"

import HomeIndexPage from "./Home/index"
import CountIndexPage from "./CountDown/index"

const HomePage = ({ countDownDate, formDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(countDownDate)
  const [fdays, fhours, fminutes, fseconds] = useCountdown(formDate)
  var fData = {
    days: fdays,
    hours: fhours,
    minutes: fminutes,
    seconds: fseconds,
  }
  if (days + hours + minutes + seconds <= 0) {
    return <HomeIndexPage />
  } else {
    return (
      <CountIndexPage
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
        fData={fData}
      />
    )
  }
}

export default HomePage
