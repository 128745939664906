import React, { useEffect } from "react"
import { Container, Figure, Button } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

const LandingPage = props => {
  useEffect(() => {
    document.body.style.backgroundColor = "#c9085a"
  }, [])

  const handleSubmit = () => {
    props.setHomePage(true)
    props.setLandingPage(false)
  }
  return (
    <section className="landing-page site-bg py-5 text-center">
      <Container className="d-flex flex-column align-items-center justify-content-center">
        <h1>Your next favorite TWIX flavor is here</h1>
        <div className="d-flex mt-3">
          <Figure>
            <StaticImage
              src="../../images/twix-left-two-bars.png"
              alt="Twix left Two Bars"
              quality={100}
              placeholder="none"
            />
          </Figure>
          <Figure>
            <StaticImage
              src="../../images/landing-twix-logo.png"
              alt="Twix Logo"
              quality={100}
              placeholder="none"
            />
          </Figure>
          <Figure>
            <StaticImage
              src="../../images/twix-right-two-bars.png"
              alt="Twix Right Two Bars"
              quality={100}
              placeholder="none"
            />
          </Figure>
        </div>
        <h3 className="text-uppercase">
          Get an exclusive First taste, while supplies last.
        </h3>
        <div className="text-center">
          <Button
            size="lg"
            className="text-uppercase mt-3 mt-md-4"
            type="submit"
            onClick={handleSubmit}
            // onClick={() => {
            //   props.setHomePage(true)
            //   props.setLandingPage(false)
            // }}
          >
            Get a box
          </Button>
        </div>
      </Container>
    </section>
  )
}
export default LandingPage
