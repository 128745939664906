import React, { useState, useEffect } from "react"

import HomePage from "./HomePage"
import LandingPage from "./LandingPage"
import SoldOutPage from "./SoldOutPage"
import ThankYouPage from "./ThankYouPage"

const IndexPage = props => {
  const [landingPage, setLandingPage] = useState(true)
  const [homePage, setHomePage] = useState(false)
  const [thankYouPage, setThankYouPage] = useState(false)
  const [soldOutPage, setSoldOutPage] = useState(false)

    return (
    <>
      {landingPage && (
        <LandingPage
          setLandingPage={setLandingPage}
          setHomePage={setHomePage}
          setSoldOutPage={setSoldOutPage}
        />
      )}
      {homePage && (
        <HomePage
          setHomePage={setHomePage}
          setThankYouPage={setThankYouPage}
          setSoldOutPage={setSoldOutPage}
        />
      )}
      {thankYouPage && <ThankYouPage />}
      {soldOutPage && <SoldOutPage />}
    </>
  )
}
export default IndexPage
