import React, { useState, useEffect, useRef } from "react"
import { useForm } from "react-hook-form"

// import axios from "axios"

import { Row, Col, Form, Button } from "react-bootstrap"

const WrapUpForm = props => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  // const recaptchaRef = useRef()

  const [captchaError, setCaptchaError] = useState(false)

  const onSubmit = async (data, event) => {
    event.preventDefault()
    props.setSoldOutPage(false)
    props.setHomePage(false)
    props.setThankYouPage(true)
  }
  const onInvalid = e => {
    e.target.setCustomValidity("Please Enter a Valid Contact Number")
  }
  return (
    <Form
      className="my-2 my-md-3"
      onSubmit={handleSubmit(onSubmit)}
      //autoComplete="off"
    >
      <Row className="form-bg mx-1 mx-md-0">
        <h5 className="text-center mt-3 mt-md-0 pt-4 pt-md-5">
          Get one step closer to creamy, crunchy deliciousness{" "}
          <span className="d-md-block">by submitting the form below.</span>
        </h5>
        <Col className="col-md-10 offset-md-1">
          {/* lg={{ span: 9, offset: 1 }}> */}
          <Row>
            <Col lg={6} className="mb-4 text-center">
              <Form.Group
                controlId="firstName"
                className="d-flex align-items-end justify-content-between"
              >
                <Form.Label>First&nbsp;Name:</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="50"
                  className="rounded-0"
                  {...register("firstName", { required: true })}
                />
              </Form.Group>
              {errors.firstName && (
                <span className="input-error">First name is required</span>
              )}
            </Col>

            <Col lg={6} className="mb-4 text-center">
              <Form.Group
                controlId="lastName"
                className="d-flex align-items-end justify-content-between"
              >
                <Form.Label>Last&nbsp;Name:</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="50"
                  className="rounded-0"
                  {...register("lastName", { required: true })}
                />
              </Form.Group>
              {errors.lastName && (
                <span className="input-error">Last name is required</span>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="mb-4 text-center">
              <Form.Group
                controlId="email"
                className="d-flex align-items-end justify-content-between"
              >
                <Form.Label>Email:</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="50"
                  className="rounded-0"
                  {...register("email", { required: true })}
                />
              </Form.Group>
              {errors.email && (
                <span className="input-error">Email is required</span>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mb-4 text-center">
              <Form.Group
                controlId="phoneNumber"
                className="d-flex align-items-end justify-content-between"
              >
                <Form.Label>Mobile&nbsp;Number:</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="15"
                  minLength="10"
                  className="rounded-0"
                  pattern="[0-9\-()]{1,15}"
                  onChange={onInvalid}
                  {...register("mobilenumber", { required: true })}
                />
              </Form.Group>
              {errors.mobilenumber && (
                <span className="input-error">Contact number is required</span>
              )}
            </Col>

            <Col lg={6} className="mb-4 text-center">
              <Form.Group
                controlId="street"
                className="d-flex align-items-end justify-content-between"
              >
                <Form.Label>Street:</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="50"
                  className="rounded-0"
                  {...register("street", { required: true })}
                />
              </Form.Group>
              {errors.street && (
                <span className="input-error">Street is required</span>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={4} className="mb-4 text-center">
              <Form.Group
                controlId="city"
                className="d-flex align-items-end justify-content-between"
              >
                <Form.Label>City:</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="50"
                  className="rounded-0"
                  {...register("city", { required: true })}
                />
              </Form.Group>
              {errors.city && (
                <span className="input-error">City is required</span>
              )}
            </Col>

            <Col lg={4} className="mb-4 text-center">
              <Form.Group
                controlId="state"
                className="d-flex align-items-end justify-content-between"
              >
                <Form.Label>State:</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="50"
                  className="rounded-0"
                  {...register("state", { required: true })}
                />
              </Form.Group>
              {errors.state && (
                <span className="input-error">State is required</span>
              )}
            </Col>
            <Col lg={4} className="mb-4 text-center">
              <Form.Group
                controlId="zip"
                className="d-flex align-items-end justify-content-between"
              >
                <Form.Label>Zip:</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="50"
                  className="rounded-0"
                  {...register("zip", { required: true })}
                />
              </Form.Group>
              {errors.zip && (
                <span className="input-error">Zip is required</span>
              )}
            </Col>
          </Row>
          <Form.Group className="checkbox">
            <Row>
              <Col lg={1} className="col-1">
                <input
                  type="checkbox"
                  id="terms"
                  className="checkbox-consent"
                  required
                  onInvalid={e => {
                    e.target.validity.valueMissing
                      ? e.target.setCustomValidity(
                          "Please read T&C before you submit!"
                        )
                      : e.target.setCustomValidity("")
                  }}
                  onClick={e => {
                    e.target.validity.valueMissing
                      ? e.target.setCustomValidity(
                          "Please read T&C before you submit!"
                        )
                      : e.target.setCustomValidity("")
                  }}
                />
              </Col>
              <Col
                lg={11}
                className="col-11 ps-md-0 pt-3 align-self-center text-left"
              >
                <label htmlFor="terms">
                  <p className="mb-2 checkbox-text">
                    I have read and agree to the{" "}
                    <a
                      className="form-links"
                      href="/Twix_Giveaway_Terms_and_Conditions_MW.pdf"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Official Rules.
                      <span className="visually-hidden">Document(247kb)</span>
                    </a>
                  </p>
                </label>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="checkbox">
            <Row>
              <Col lg={1} className="col-1">
                <input
                  type="checkbox"
                  id="privacy"
                  className="checkbox-consent"
                  // required
                  // onInvalid={e => {
                  //   e.target.validity.valueMissing
                  //     ? e.target.setCustomValidity("Required Feild")
                  //     : e.target.setCustomValidity("")
                  // }}
                  // onClick={e => {
                  //   e.target.validity.valueMissing
                  //     ? e.target.setCustomValidity("Required Feild")
                  //     : e.target.setCustomValidity("")
                  // }}
                  {...register("consent")}
                />
              </Col>
              <Col
                lg={11}
                className="col-11 ps-md-0 pt-3 align-self-center text-left"
              >
                <label htmlFor="privacy">
                  <p className="mb-2 checkbox-text">
                    Yes! I would like to receive future marketing communications
                    from TWIX<sup>&reg;</sup> and other Mars Wrigley brands via
                    the email provided. You can opt out at any time.{" "}
                    <a
                      className="form-links"
                      href="https://www.mars.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                      <span className="visually-hidden">
                        (opens in new window)
                      </span>
                    </a>
                  </p>
                </label>
              </Col>
            </Row>
          </Form.Group>
          {/* <div>
            <ReCAPTCHA
              // Stage & Prod
              sitekey="6LdqyAUgAAAAAGa2Z7cl6oh4aLs2yZ26o7SmVcE-"
              // Production
              // sitekey=""
              size="invisible"
              ref={recaptchaRef}
            />
          </div> */}
          <div className="text-center">
            <Button
              size="lg"
              className="text-uppercase my-3 my-md-5 mb-4"
              type="submit"
            >
              Send Me TWIX<sup>&reg;</sup> Cookie Dough
            </Button>
          </div>
          <div className="text-center">
            {captchaError && (
              <h3 className="input-error pb-5">
                Oops,seems to be a trouble. Please retry submitting the form.
              </h3>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  )
}
export default WrapUpForm
