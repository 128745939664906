import React, { useState } from "react"
import { useForm } from "react-hook-form"

import { Row, Col, Form, Button } from "react-bootstrap"

const WrapUpForm = props => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [captchaError, setCaptchaError] = useState(false)

  const onSubmit = async (data, event) => {
    event.preventDefault()
      props.setShowCounter(false)
      props.setThankYouPage(true)
  }
  return (
    <Form
      className="my-2 my-md-3"
      onSubmit={handleSubmit(onSubmit)}
      //autoComplete="off"
    >
      <Row className="form-bg mx-1 mx-md-0">
        <h5 className="text-center mt-3 mt-md-0 pt-4 pt-md-5">
          We’ll let you know when TWIX<sup>&reg;</sup> Cookie Dough is out of
          the oven and up for grabs. Sign up below to receive a reminder via
          email.
        </h5>
        <p className="text-center">
          Plus, check shelves in-store once they’re available nationwide
          starting this December.
        </p>
        <Col className="col-md-10 offset-md-1">
          {/* lg={{ span: 9, offset: 1 }}> */}
          <Row>
            <Col md={6} className="mb-4 text-center">
              <Form.Group
                controlId="firstName"
                className="d-flex align-items-end justify-content-between"
              >
                <Form.Label>First&nbsp;Name:</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="50"
                  className="rounded-0"
                  {...register("firstName", { required: true })}
                />
              </Form.Group>
              {errors.firstName && (
                <span className="input-error">First name is required</span>
              )}
            </Col>

            <Col md={6} className="mb-4 text-center">
              <Form.Group
                controlId="lastName"
                className="d-flex align-items-end justify-content-between"
              >
                <Form.Label>Last&nbsp;Name:</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="50"
                  className="rounded-0"
                  {...register("lastName", { required: true })}
                />
              </Form.Group>
              {errors.lastName && (
                <span className="input-error">Last name is required</span>
              )}
            </Col>
          </Row>
          <div className="row text-center">
            <Form.Group
              controlId="email"
              className="col-12 d-flex align-items-end justify-content-between"
            >
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="text"
                maxLength="254"
                className="rounded-0"
                {...register("email", {
                  required: "Email ID is Required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid Email Address",
                  },
                })}
              />
            </Form.Group>
            {errors.email && (
              <span className="input-error">{errors.email.message}</span>
            )}
          </div>
          <Form.Group className="checkbox">
            <Row>
              <Col lg={1} className="col-1">
                <input
                  type="checkbox"
                  id="terms"
                  className="checkbox-consent"
                  required
                  onInvalid={e => {
                    e.target.validity.valueMissing
                      ? e.target.setCustomValidity(
                          "Please read T&C before you submit!"
                        )
                      : e.target.setCustomValidity("")
                  }}
                  onClick={e => {
                    e.target.validity.valueMissing
                      ? e.target.setCustomValidity(
                          "Please read T&C before you submit!"
                        )
                      : e.target.setCustomValidity("")
                  }}
                />
              </Col>
              <Col
                lg={11}
                className="col-11 ps-md-0 pt-3 align-self-center text-left"
              >
                <label htmlFor="terms">
                  <p className="mb-2 checkbox-text">
                    I have read and agree to the{" "}
                    <a
                      className="form-links"
                      href="/Twix_Giveaway_Terms_and_Conditions_MW.pdf"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Official Rules.
                      <span className="visually-hidden">Document(247kb)</span>
                    </a>
                  </p>
                </label>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group  className="checkbox">
            <Row>
              <Col md={1} className="col-1">
                <input
                  type="checkbox"
                  id="privacy"
                  className="checkbox-consent"
                  // required
                  // onInvalid={e => {
                  //   e.target.validity.valueMissing
                  //     ? e.target.setCustomValidity("Required Feild")
                  //     : e.target.setCustomValidity("")
                  // }}
                  // onClick={e => {
                  //   e.target.validity.valueMissing
                  //     ? e.target.setCustomValidity("Required Feild")
                  //     : e.target.setCustomValidity("")
                  // }}
                  {...register("consent")}
                />
              </Col>
              <Col
                md={11}
                className="col-11 ps-md-0 pt-3 align-self-center text-left"
              >
                <label htmlFor="privacy">
                  <p className="mb-2 checkbox-text">
                    Yes! I would like to receive future marketing communications
                    from TWIX<sup>&reg;</sup> and other Mars Wrigley brands via
                    the email provided. You can opt out at any time.
                  </p>
                </label>
              </Col>
            </Row>
          </Form.Group>
          <div className="text-center">
            <Button
              size="lg"
              className="text-uppercase my-3 my-md-5 mb-4"
              type="submit"
            >
              submit
            </Button>
          </div>
          <div className="text-center">
            {captchaError && (
              <h3 className="input-error pb-5">
                Oops,seems to be a trouble. Please retry submitting the form.
              </h3>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  )
}
export default WrapUpForm
