import React, { useEffect } from "react"
import { Row, Figure } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

const ThankYouPage = props => {
  useEffect(() => {
    document.body.style.backgroundColor = "#fce9d1"
  }, [])
  return (
    <section className="thank-you-page py-5 text-center position-relative">
      <Figure>
        <StaticImage
          className="position-absolute twix-bar-left d-lg-none d-block"
          src="../../images/twix-bar.png"
          alt="Twix Choclate Bar"
          quality={100}
          placeholder="none"
        />
      </Figure>
      <div className="mt-3 position-relative">
        <Row>
          <div className="col-1">
            <Figure>
              <StaticImage
                className="position-absolute twix-bar-left d-lg-block d-none"
                src="../../images/twix-bar.png"
                alt="Twix Choclate Bar"
                quality={100}
                placeholder="none"
              />
            </Figure>
          </div>
          <div className="col-10">
            <h1 className="py-lg-5 pt-5 py-3 mx-3">
              Make your <span className="d-md-block">friends jealous!</span>
            </h1>
          </div>
          <div className="col-1">
            <Figure>
              <StaticImage
                className="position-absolute twix-bar-right d-lg-block d-none"
                src="../../images/twix-bar.png"
                alt="Twix Choclate Bar"
                quality={100}
                placeholder="none"
              />
            </Figure>
          </div>
        </Row>
      </div>
      <h5 className="pt-lg-5 mx-5 mx-lg-3">
        Your exclusive first taste of TWIX<sup>&reg;</sup> Cookie Dough will
        arrive soon.
      </h5>
      <h6 className="text-uppercase pt-4">Brag about it</h6>
      <div className="pb-5 pb-lg-0">
        <a
          className="text-decoration-none"
          href="https://www.instagram.com/twix"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Figure>
            <StaticImage
              className="social-icons me-3"
              src="../../images/instagram-icon.png"
              alt="Instagram Icon"
              quality={100}
              placeholder="none"
            />
          </Figure>
          <span className="visually-hidden">(opens in new window)</span>
        </a>
        <a
          className="text-decoration-none"
          href="https://twitter.com/twix"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Figure>
            <StaticImage
              className="social-icons ms-3"
              src="../../images/twitter-icon.png"
              alt="Twitter Icon"
              quality={100}
              placeholder="none"
            />
          </Figure>
          <span className="visually-hidden">(opens in new window)</span>
        </a>
      </div>
      <Figure>
        <StaticImage
          className="position-absolute twix-bar-right d-lg-none d-block"
          src="../../images/twix-bar.png"
          alt="Twix Choclate Bar"
          quality={100}
          placeholder="none"
        />
      </Figure>
    </section>
  )
}
export default ThankYouPage
