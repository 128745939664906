import React from "react"
import { Container, Figure, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

import Form from "./Form"

const HomePage = ({ setHomePage, setThankYouPage, setSoldOutPage }) => {

  return (
    <section className="homepage site-bg py-5">
      <Container className="mt-3">
        <Row className="align-items-center">
          <Col lg={5} className="text-center">
            <Figure>
              <StaticImage
                src="../../images/twix-logo.png"
                alt="Twix Logo"
                quality={100}
                placeholder="none"
              />
            </Figure>
          </Col>
          <Col lg={7} className="content px-3 py-4 py-lg-0">
            <h5>Forget the spoon.</h5>
            <h1>
              You’re about to be one of the first to try TWIX® Cookie Dough.
            </h1>
          </Col>
        </Row>
      </Container>
      <div className="position-relative">
        <Container>
          <Row>
            <Col md={2}>
              <Figure>
                <StaticImage
                  className="position-absolute twix-left"
                  src="../../images/twix-left.png"
                  alt="Twix Choclate Left"
                  quality={100}
                  placeholder="none"
                />
              </Figure>
            </Col>
            <Col md={8}>
              <Form
                setHomePage={setHomePage}
                setThankYouPage={setThankYouPage}
                setSoldOutPage={setSoldOutPage}
              />
            </Col>
            <Col md={2}>
              <Figure>
                <StaticImage
                  className="position-absolute twix-right"
                  src="../../images/twix-right.png"
                  alt="Twix Choclate Right"
                  quality={100}
                  placeholder="none"
                />
              </Figure>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  )
}
export default HomePage
